<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a class="navbar-brand" [routerLink]="'dashboard'">
        <img
          src="../../../assets/images/Osos/osos-logo.svg"
          alt=""
          height="38px"
          *ngIf="!isNavbarCollapsed"
        />
        <img
          src="../../../assets/images/Osos/osos_logo_icon_square_white.png"
          alt=""
          height="38px"
          *ngIf="isNavbarCollapsed"
        />
      </a>
    </div>
    <div
      class="collapse navbar-collapse"
      [ngClass]="isNavbarCollapsed ? '' : 'show'"
    >
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-button
            [routerLink]="'dashboard'"
            class="sidemenu-collapse nav-notification-icons"
          >
            <i class="material-icons-outlined icon-color"> home </i>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="fullscreen">
          <button
            mat-button
            class="nav-notification-icons"
            (click)="callFullscreen()"
          >
            <i class="material-icons-outlined icon-color"> fullscreen </i>
          </button>
        </li>
        <li>
          <button
            mat-button
            [matMenuTriggerFor]="notificationMenu"
            class="nav-notification-icons"
            (click)="handleOnClickNotification()"
          >
            <i class="material-icons-outlined icon-color">
              notifications_active
            </i>
            <span class="badge bg-red notification-badge">{{
              notificationUnreadCount > 9 ? '9+' : notificationUnreadCount
            }}</span>
          </button>

          <mat-menu
            #notificationMenu="matMenu"
            class="nfc-menu"
            xPosition="before"
            [hasBackdrop]="true"
          >
            <div class="nfc-header" (click)="$event.stopPropagation()">
              <h5 class="mb-0">Notifications</h5>

              <div></div>
            </div>
            <div class="row p-2" (click)="$event.stopPropagation()">
              <div class="col-md-6">
                <a
                  (click)="markAllNotificationsAsRead()"
                  class="btn btn-sm btn-outline-primary"
                  >Mark All As Read</a
                >
              </div>
              <div class="col-md-6">
                <form [formGroup]="notificationForm">
                  <mat-slide-toggle
                    formControlName="isUnread"
                    (change)="showUnreadOrReadNotifications($event)"
                    ><span style="font-size: 11px"
                      >Show Only Unread</span
                    ></mat-slide-toggle
                  >
                </form>
              </div>
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <app-loader *ngIf="isLoading"></app-loader>
                    <div *ngIf="!isLoading">
                      <button
                        mat-menu-item
                        *ngFor="let notification of notificationList"
                        onClick="return false;"
                        [ngClass]="{ 'msg-unread': !notification.isRead }"
                        (click)="setNotificationMarkAsRead(notification.id)"
                      >
                        <span class="table-img msg-user">
                          <i
                            class="material-icons-two-tone nfc-type-icon"
                            [ngClass]="{ 'nfc-green': true }"
                            >notifications_active</i
                          >
                        </span>
                        <span class="menu-info">
                          <span class="menu-title truncate-title">{{
                            notification.title
                          }}</span>
                          <p
                            [innerHTML]="notification.body"
                            class="truncate-body"
                          ></p>

                          <span class="menu-desc mt-1">
                            <i class="material-icons">access_time</i>
                            {{ getFromNow(notification.sentAt) }} ago
                          </span>
                        </span>
                        <span class="nfc-close"> </span>
                      </button>
                      <span class="footer-content container">
                        <center class="center-container">
                          <span *ngIf="notificationForm.get('isUnread').value">
                            {{
                              notificationList.length != 0
                                ? 'That is all your unread notifications from the last 30 days.'
                                : 'You have read all your notifications from the last 30 days.'
                            }}
                          </span>
                          <span *ngIf="!notificationForm.get('isUnread').value">
                            {{
                              notificationList.length != 0
                                ? 'That is all your notifications from the last 30 days.'
                                : 'You have not received notifications yet.'
                            }}
                          </span>
                        </center>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </mat-menu>
        </li>

        <li class="nav-item">
          &nbsp;
          <button
            mat-button
            [matMenuTriggerFor]="languagemenu"
            class="lang-dropdown nav-notification-icons"
          >
            <span *ngIf="flagvalue !== undefined" class="align-middle">{{
              flagvalue
            }}</span>
            <span *ngIf="flagvalue === undefined" class="align-middle">{{
              defaultFlag
            }}</span>
            <span><mat-icon class="icon-container">expand_more</mat-icon></span>
          </button>
          <mat-menu #languagemenu="matMenu">
            <div *ngFor="let item of listLang" class="lang-item">
              <button
                mat-menu-item
                class="dropdown-item lang-item-list"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{ active: langStoreValue === item.lang }"
              >
                <span class="align-middle">{{ item.text }}</span>
              </button>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item company-select-nav">
          <button
            mat-button
            [matMenuTriggerFor]="companymenu"
            class="lang-dropdown nav-notification-icons company-select-btn"
          >
            <img
              *ngIf="currentCompany"
              src="{{
                currentCompany?.image
                  ? currentCompany?.image
                  : '../../../assets/images/company-default.png'
              }}"
              height="16"
              class="company-img"
            />
            {{ currentCompany?.name }}
          </button>
          <mat-menu
            #companymenu="matMenu"
            class="company-menu"
            style="max-height: 500px !important"
          >
            <div class="w-100">
              <mat-spinner
                class="mx-auto"
                *ngIf="isCompaniesLoading"
                diameter="20"
              ></mat-spinner>
            </div>

            <div class="div-lst">
              <div *ngFor="let item of this.companyLookup" class="lang-item">
                <button
                  mat-menu-item
                  class="dropdown-item lang-item-list"
                  (click)="onChangeCompany(item)"
                  [ngClass]="{ active: currentCompany?.id === item?.id }"
                >
                  <img
                    src="{{
                      item?.image
                        ? item?.image
                        : '../../../assets/images/company-default.png'
                    }}"
                    class="company-img"
                    height="12"
                  />
                  <span class="align-middle"> {{ item.name }}</span>
                </button>
              </div>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="pt-0">
              <img
                src="../../../assets/images/user/img_avatar.png"
                class="user_img"
                width="32"
                height="32"
                alt="User"
              />
              <span>{{ this.currentUser?.name ?? '' }}</span>
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons
                      [icon]="'log-out'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >{{ 'COMMON.LOGOUT' | translate }}
                  </button>
                  <button mat-menu-item (click)="onClickChangePassword()">
                    <app-feather-icons
                      [icon]="'key'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >{{ 'COMMON.CHANGE_PASSWORD' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-change-password></app-change-password>
